import { useRef } from 'react';
import {
  DialogActionTrigger,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogBody,
  DialogRoot,
  DialogTitle,
} from 'chakra/snippets/dialog';
import { Button } from 'chakra/snippets/button';

const Dialog = ({
  open,
  onClose,
  onSubmit,
  children,
  title,
  width,
  fullscreen,
  submitText = 'Save',
  cancelText = 'Cancel',
  submitButtonProps,
  submitLoadingText,
  submitButtonIcon,
  cancelButtonProps,
}) => {
  const ref = useRef(null);

  return (
    <DialogRoot
      scrollBehavior="inside"
      lazyMount
      open={open}
      autoFocus={false}
      initialFocusEl={() => ref.current}
      {
        ...fullscreen && {
          size: 'full',
        }
      }
    >
      <DialogContent
        w={width || '100%'}
        transition="width 0.2s ease-in-out, max-width 0.2s ease-in-out"
        {
          ...width && {
            maxW: width
          }
        }
      >
        <DialogHeader>
          <DialogTitle>{ title }</DialogTitle>
        </DialogHeader>
        <DialogBody>
          { children }
        </DialogBody>
        <DialogFooter>
          <DialogActionTrigger asChild>
            <Button
              variant="outline"
              {...cancelButtonProps}
              onClick={() => onClose()}
            >
              { cancelText }
            </Button>
          </DialogActionTrigger>
          {
            onSubmit ? (
              <Button
                loadingText={submitLoadingText}
                {
                  ...submitButtonProps || {
                    colorPalette: 'blue',
                  }
                }
                onClick={onSubmit}
              >
                { submitButtonIcon }
                { submitText }
              </Button>
            ) : null
          }
        </DialogFooter>
        <DialogCloseTrigger onClick={() => onClose()} />
      </DialogContent>
    </DialogRoot>
  )
}

export default Dialog;
