import { Textarea as TextareaSnippet } from '@chakra-ui/react';

const Textarea = ({
  readOnly,
  placeholder,
  disabled,
  value,
  invalid,
  overridden,
  onChange,
}) => (
  <TextareaSnippet
    autoresize
    bg="#fff"
    readOnly={readOnly}
    color="black"
    placeholder={placeholder}
    disabled={disabled}
    value={value || ''}
    variant={disabled ? 'subtle' : 'outline'}
    onChange={onChange}
    onBlur={onChange}
    {
      ...!invalid && {
        focusRingColor: 'primary',
      }
    }
    {
      ...overridden && {
        bg: '#f1f7f9',
        borderWidth: 2,
        outline: 'none',
        borderColor: 'primary',
      }
    }
  />
);

export default Textarea;
