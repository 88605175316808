'use client';

import { For, SegmentGroup } from '@chakra-ui/react';
import * as React from 'react';

function normalize(items) {
  return items.map((item) => {
    if (typeof item === 'string') return { value: item, label: item };
    return item;
  });
}

export const SegmentedControl = React.forwardRef(
  (props, ref) => {
    const {
      items,
      value,
      color,
      ...rest
    } = props;
    const data = React.useMemo(() => normalize(items), [items]);

    return (
      <SegmentGroup.Root
        ref={ref}
        value={value}
        {...rest}
      >
        <SegmentGroup.Indicator
          {
            ...color && {
              bg: color,
            }
          }
        />
        <For each={data}>
          {(item) => (
            <React.Fragment key={item.value}>
              <SegmentGroup.Item
                value={item.value}
                disabled={item.disabled}
              >
                <SegmentGroup.ItemText
                  color={color && item.value === value ? '#fff' : 'inherit'}
                >
                  { item.label }
                </SegmentGroup.ItemText>
                <SegmentGroup.ItemHiddenInput />
              </SegmentGroup.Item>
            </React.Fragment>
          )}
        </For>
      </SegmentGroup.Root>
    );
  },
);
