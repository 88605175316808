import { useEffect, useRef } from 'react';
import SessionDebugger from '@multiplayer-app/session-debugger';
import './SessionDebuggerWidget.scss';

const SessionDebuggerWidget = ({ isExpanded }) => {
  const containerRef = useRef();
  const btn = SessionDebugger.sessionWidgetButtonElement;

  useEffect(() => {
    if (btn && process.env.NODE_ENV !== 'development') {
      const isLeftSide = btn.classList.contains('button-leftside');
      if (!isLeftSide) {
        btn.classList.add('button-leftside');
      }
      btn.classList.add('no-draggable', 'no-tooltip');
      containerRef.current.append(btn);
      return () => {
        btn.classList.remove('no-draggable', 'no-tooltip');
        if (!isLeftSide) {
          btn.classList.remove('button-leftside');
        }
        const mpRoot = document.querySelector('mp-root');
        if (mpRoot) {
          mpRoot.append(btn);
        } else {
          document.body.append(btn);
        }
      };
    }
  }, [isExpanded, process.env.NODE_ENV]);

  if (process.env.NODE_ENV !== 'development') {
    return (
      <div ref={containerRef} className="ramp-session-debugger-widget" />
    );
  }

  return null;
};

export default SessionDebuggerWidget;
