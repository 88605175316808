import {
  NumberInputField,
  NumberInputRoot,
} from 'chakra/snippets/number-input';

const Number = ({
  readOnly,
  placeholder,
  disabled,
  value,
  invalid,
  overridden,
}) => (
  <NumberInputRoot
    w="100%"
    bg="#fff"
    disabled={disabled}
    readOnly={readOnly}
    variant={disabled ? 'subtle' : 'outline'}
  >
    <NumberInputField
      color="black"
      placeholder={placeholder}
      value={value}
      {
        ...!invalid && {
          focusRingColor: 'primary',
        }
      }
      {
        ...overridden && {
          bg: '#f1f7f9',
          borderWidth: 2,
          outline: 'none',
          borderColor: 'primary',
        }
      }
    />
  </NumberInputRoot>
);

export default Number;
