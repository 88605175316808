import { TableVirtuoso } from 'react-virtuoso';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Text,
  Button,
  Flex,
  Spinner,
  Box,
  Table,
} from '@chakra-ui/react';
import { Checkbox } from 'chakra/snippets/checkbox';
import Menu from 'chakra/Menu';
import {
  LuArrowUp,
  LuArrowDown,
  LuChevronRight,
  LuChevronDown,
} from 'react-icons/lu';
import { FaInbox } from 'react-icons/fa';
import {
  PaginationItems,
  PaginationNextTrigger,
  PaginationPrevTrigger,
  PaginationRoot,
} from 'chakra/snippets/pagination';
import { EmptyState } from 'chakra/snippets/empty-state';

const TABLE_ACCENT_COLOR = '#e4e4e7';
const TABLE_HEADER_BACKGROUND_COLOR = '#f4f4f5';
const TABLE_SELECTABLE_COLUMN_WIDTH = 50;
const TABLE_EXPANDABLE_COLUMN_WIDTH = 62;

const flatten = (arr, expandedRows) => {
  const result = [];

  const traverse = (items) => {
    items.forEach((item) => {
      result.push(item);

      if (Array.isArray(item.children) && item.children.length && expandedRows[item._id]) {
        traverse(item.children.map(child => ({
          ...child,
          parentId: item._id,
        })));
      }
    });
  }

  traverse(arr);
  return result;
}

const leftFixedColumnPosition = ({
  selectable,
  expandable,
  noData
}) => {
  let left = 0;

  if (noData) {
    return left;
  }

  if (selectable) {
    left += TABLE_SELECTABLE_COLUMN_WIDTH;
  }

  if (expandable) {
    left += TABLE_EXPANDABLE_COLUMN_WIDTH;
  }

  return left;
}

const Cell = React.memo(({
  row,
  column,
  selectable,
  expandable,
  noData,
}) => (
  <td
    style={{
      ...column.fixed === 'left' && {
        position: 'sticky',
        left: `${leftFixedColumnPosition({ selectable, expandable, noData })}px`,
        zIndex: 1,
      },
      ...column.fixed === 'right' && {
        position: 'sticky',
        right: 0,
        zIndex: 1,
      },
      height: '44px',
      backgroundColor: '#fff',
      padding: '4px 8px',
      textAlign: 'left',
      fontSize: '14px',
      ...column.fixed === 'right' ? {
        boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset 1px 0 0 ${TABLE_ACCENT_COLOR}`,
      } : {
        boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset -1px 0 0 ${TABLE_ACCENT_COLOR}`,
      },
    }}
  >
    {
      column.cell
        ? column.cell(row[column.key], row)
        : (
          <Text truncate>
            { row[column.key] }
          </Text>
        )
    }
  </td>
));

const HeaderCell = React.memo(({
  column,
  selectable,
  expandable,
  noData,
  sorting,
  setSorting,
  handleSort,
}) => {
  const onClick = useCallback(() => {
    if (column.sortable && setSorting) {
      handleSort(column.key);
    }
  }, [column, setSorting, handleSort]);

  return (
    <th
      {
        ...column.sortable && setSorting ? {
          onClick,
        } : {}
      }
      style={{
        userSelect: 'none',
        width: column.width,
        minWidth: column.width,
        height: '55px',
        ...column.fixed === 'left' && {
          position: 'sticky',
          left: `${leftFixedColumnPosition({ selectable, expandable, noData })}px`,
          zIndex: 1,
        },
        ...column.fixed === 'right' && {
          position: 'sticky',
          right: 0,
          zIndex: 1,
        },
        padding: '4px 8px',
        textAlign: 'left',
        fontSize: '14px',
        ...column.sortable && {
          cursor: 'pointer',
        },
        ...column.fixed === 'right' ? {
          boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset 1px 0 0 ${TABLE_ACCENT_COLOR}`,
        } : {
          boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset -1px 0 0 ${TABLE_ACCENT_COLOR}`
        },
        backgroundColor: sorting?.key === column.key
          ? TABLE_ACCENT_COLOR
          : TABLE_HEADER_BACKGROUND_COLOR
      }}
    >
      <Flex alignItems="center">
        { column.header ? column.header() : column.title }
        {
          column.sortable && setSorting && sorting?.key === column.key && (
            <Text ml={2}>
              {
                sorting?.direction === 'asc'
                  ? <LuArrowUp />
                  : <LuArrowDown />
              }
            </Text>
          )
        }
      </Flex>
    </th>
  )
});

const CellCheckbox = React.memo(({
  row,
  selectedRowsSet,
  onToggleSelect,
  expandedRowSelectable,
}) => (
  <Checkbox
    bg="#fff"
    cursor="pointer"
    colorPalette="cyan"
    checked={selectedRowsSet.has(row._id)}
    onClick={e => {
      e.stopPropagation();
    }}
    onCheckedChange={() => {
      if (row.parentId) {
        onToggleSelect(row._id, row.parentId)
        return;
      }

      onToggleSelect(
        row._id,
        null,
        expandedRowSelectable ? row.children?.map((child) => child._id) || [] : [],
      )
    }}
  />
));

const MemoizedTable = React.memo(({ style, ...props }) => (
  <Table.Root
    {...props}
    style={{
      ...style,
      tableLayout: 'fixed',
      width: '100%',
    }}
  />
));

const MemoizedRow = React.memo(({ item, onRowClick, ...props }) => (
  <Table.Row
    { ...props }
    {
      ...onRowClick && {
        cursor: 'pointer',
        onClick: () => onRowClick(item)
      }
    }
    _hover={{ '& td': { backgroundColor: '#f4f4f5 !important' } }}
  />
));

const MemoizedExpandControl = React.memo(({
  row,
  onToggleExpand,
  onRowSelect,
  expandable,
  expandedRows,
}) => {
  if (onToggleExpand && expandable) {
    return (
      <td
        style={{
          position: 'sticky',
          left: !!onRowSelect ? TABLE_SELECTABLE_COLUMN_WIDTH : 0,
          width: `${TABLE_SELECTABLE_COLUMN_WIDTH}px`,
          minWidth: `${TABLE_SELECTABLE_COLUMN_WIDTH}px`,
          backgroundColor: '#fff',
          textAlign: 'left',
          fontSize: '14px',
          boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset -1px 0 0 ${TABLE_ACCENT_COLOR}`,
          padding: '4px 15px',
        }}
      >
        {
          row.children?.length ? (
            <Button
              p={0}
              size="xs"
              variant="ghost"
              _hover={{ bg: 'gray.200' }}
              onClick={(e) => {
                e.stopPropagation();
                onToggleExpand(row._id);
              }}
            >
              {
                !!expandedRows[row._id]
                  ? <LuChevronDown style={{ width: 20, height: 20 }} />
                  : <LuChevronRight style={{ width: 20, height: 20 }} />
              }
            </Button>
          ) : null
        }
      </td>
    )
  }
});

const MemoizedCellSelection = React.memo(({
  row,
  onRowSelect,
  onToggleSelect,
  selectedRowsSet,
  expandedRowSelectable,
}) => {
  if (onRowSelect) {
    const showCheckbox = !row?.children
      ? expandedRowSelectable
      : true;

    return (
      onRowSelect ? (
        <td
          style={{
            position: 'sticky',
            left: 0,
            width: `${TABLE_SELECTABLE_COLUMN_WIDTH}px`,
            minWidth: `${TABLE_SELECTABLE_COLUMN_WIDTH}px`,
            backgroundColor: '#fff',
            textAlign: 'left',
            fontSize: '14px',
            boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset -1px 0 0 ${TABLE_ACCENT_COLOR}`,
            padding: '4px 15px',
          }}
        >
          {
            showCheckbox ? (
              <CellCheckbox
                row={row}
                selectedRowsSet={selectedRowsSet}
                onToggleSelect={onToggleSelect}
                expandedRowSelectable={expandedRowSelectable}
              />
            ) : null
          }
        </td>
      ) : null
    )
  }
});

const MemoizedSelectAllHandler = React.memo(({
  onRowSelect,
  noData,
  isAllSelected,
  handleSelectAll,
}) => {
  if (onRowSelect && !noData) {
    return (
      <th
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 1,
          width: `${TABLE_SELECTABLE_COLUMN_WIDTH}px`,
          minWidth: `${TABLE_SELECTABLE_COLUMN_WIDTH}px`,
          textAlign: 'left',
          fontSize: '14px',
          boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset -1px 0 0 ${TABLE_ACCENT_COLOR}`,
          padding: '4px 15px',
          backgroundColor: TABLE_HEADER_BACKGROUND_COLOR,
        }}
      >
        <Checkbox
          bg="#fff"
          cursor="pointer"
          colorPalette="cyan"
          checked={isAllSelected}
          onCheckedChange={handleSelectAll}
        />
      </th>
    )
  }
});

const DataTable = ({
  data,
  columns,
  height = '400px',
  maxHeight = 'auto',
  onRowSelect,
  onRowClick,
  selectedRows = [],
  loading,
  pagination,
  setPagination,
  total,
  expandable,
  sorting,
  setSorting,
  expandedRowSelectable = true,
}) => {
  const [expandedRows, setExpandedRows] = useState({});
  const selectedRowsSet = useMemo(() => new Set(selectedRows), [selectedRows]);
  const noData = useMemo(() => !data.length, [
    data.length,
  ]);

  const nodes = useMemo(() => expandable ? flatten(data, expandedRows) : data, [
    data,
    expandable,
    expandedRows,
  ]);

  const isAllSelected = useMemo(() => {
    const dataLength = data?.reduce(
      (acc, row) => acc + 1 + (expandedRowSelectable ? row?.children?.length || 0 : 0),
      0,
    );
    if (selectedRows.length && selectedRows.length !== dataLength) {
      return 'indeterminate';
    }
    return selectedRows.length === dataLength;
  }, [data, selectedRows]);

  const onToggleExpand = useCallback((rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  }, []);

  const handleSelectAll = useCallback(() => {
    onRowSelect((prev) => {
      const totalRows = data.reduce(
        (acc, row) => acc + 1 + (expandedRowSelectable ? row?.children?.length || 0 : 0),
        0
      );
      if (prev.length === totalRows) {
        return [];
      } else {
        return data.flatMap((row) => [
          row._id,
          ...expandedRowSelectable ? (row?.children?.map((child) => child._id) || []) : [],
        ]);
      }
    });
  }, [onRowSelect, expandedRowSelectable, data]);

  const handleSort = useCallback(
    (key) => {
      if (!setSorting) return;
      const updatedSorting =
        sorting?.key === key
          ? sorting.direction === 'asc'
            ? { key, direction: 'desc' }
            : null
          : { key, direction: 'asc' };
      setSorting(updatedSorting);
    },
    [sorting, setSorting]
  );

  const onToggleSelect = useCallback(
    (id, parentId = null, children = []) => {
      onRowSelect((prev) => {
        const isSelected = prev.includes(id);
        let newSelection = [...prev];

        console.log(children)

        if (children.length) {
          newSelection = isSelected
            ? newSelection.filter(
              (prevId) => prevId !== id && !children.includes(prevId)
            )
            : [...newSelection, id, ...children];
        } else {
          newSelection = isSelected
            ? newSelection.filter((prevId) => prevId !== id)
            : [...newSelection, id];
          if (parentId) {
            const parentRow = data.find((row) => row._id === parentId);
            if (parentRow) {
              const allChildrenSelected = parentRow.children.every((child) =>
                newSelection.includes(child._id)
              );
              if (allChildrenSelected) {
                newSelection.push(parentId);
              } else {
                newSelection = newSelection.filter(
                  (prevId) => prevId !== parentId
                );
              }
            }
          }
        }
        return newSelection;
      });
    },
    [onRowSelect, data, expandedRowSelectable]
  );

  return (
    <Box
      w="full"
      h="full"
      borderWidth="1px"
      borderColor="gray.200"
      position="relative"
    >
      <TableVirtuoso
        style={{
          height,
          maxHeight,
          backgroundColor: '#fff',
        }}
        data={nodes}
        components={{
          Table: MemoizedTable,
          TableRow: (props) => (
            <MemoizedRow
              {...props}
              onRowClick={onRowClick}
            />
          ),
        }}
        fixedHeaderContent={() => (
          <tr>
            <MemoizedSelectAllHandler
              onRowSelect={onRowSelect}
              noData={noData}
              isAllSelected={isAllSelected}
              handleSelectAll={handleSelectAll}
            />

            {
              onToggleExpand && expandable && !noData ? (
                <th
                  style={{
                    position: 'sticky',
                    left: !!onRowSelect ? TABLE_SELECTABLE_COLUMN_WIDTH : 0,
                    zIndex: 1,
                    width: `${TABLE_EXPANDABLE_COLUMN_WIDTH}px`,
                    minWidth: `${TABLE_EXPANDABLE_COLUMN_WIDTH}px`,
                    textAlign: 'left',
                    fontSize: '14px',
                    boxShadow: `inset 0 -1px 0 ${TABLE_ACCENT_COLOR}, inset -1px 0 0 ${TABLE_ACCENT_COLOR}`,
                    padding: '4px 15px',
                    backgroundColor: TABLE_HEADER_BACKGROUND_COLOR,
                  }}
                />
              ) : null
            }
            {
              columns.map(column => (
                <HeaderCell
                  key={column.key}
                  selectable={!!onRowSelect}
                  expandable={expandable}
                  noData={noData}
                  column={column}
                  sorting={sorting}
                  setSorting={setSorting}
                  handleSort={handleSort}
                />
              ))
            }
          </tr>
        )}
        itemContent={(index, row) => (
          <>
            <MemoizedCellSelection
              row={row}
              expandedRowSelectable={expandedRowSelectable}
              onRowSelect={onRowSelect}
              onToggleSelect={onToggleSelect}
              selectedRowsSet={selectedRowsSet}
            />
            <MemoizedExpandControl
              row={row}
              onRowSelect={onRowSelect}
              onToggleExpand={onToggleExpand}
              expandable={expandable}
              expandedRows={expandedRows}
            />
            {
              columns.map(column => (
                <Cell
                  key={column.key + row._id}
                  selectable={!!onRowSelect}
                  expandable={expandable}
                  noData={noData}
                  row={row}
                  column={column}
                />
              ))
            }
          </>
        )}
      />
      {
        pagination && !noData ? (
          <PaginationRoot
            h="52px"
            borderTopColor="gray.200"
            borderTopWidth={1}
            count={total}
            pageSize={pagination.limit}
            page={pagination.offset / pagination.limit + 1}
            onPageChange={(e) => {
              setPagination({
                limit: e.pageSize,
                offset: (e.page - 1) * e.pageSize,
              });
            }}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              pl={4}
              pr={4}
              h="full"
            >
              <Text textStyle="sm">
                Total: {total.toLocaleString()} records
              </Text>
              <Flex justifyContent="center">
                <PaginationPrevTrigger />
                <PaginationItems />
                <PaginationNextTrigger />
              </Flex>
              <Menu
                onSelect={(newLimit) =>
                  setPagination({
                    limit: newLimit,
                    offset: 0,
                  })
                }
                trigger={
                  <Button variant="outline" size="sm" bg="#fff">
                    {pagination.limit} / Page
                  </Button>
                }
                options={[
                  { label: '10', value: 10 },
                  { label: '25', value: 25 },
                  { label: '50', value: 50 },
                  { label: '100', value: 100 },
                ]}
              />
            </Flex>
          </PaginationRoot>
        ) : null
      }
      {
        noData && !loading ? (
          <EmptyState
            position="absolute"
            top="50%"
            left="50%"
            width='400px'
            transform="translate(-50%, -50%)"
            icon={<FaInbox />}
            title="No results found"
            description="Try adjusting your search"
          />
        ) : null
      }
      {
        loading ? (
          <Flex
            w="100%"
            h="100%"
            position="absolute"
            top="0"
            left="0"
            bg="bg/50"
            zIndex={100}
            alignItems="center"
            justifyContent="center"
          >
            <Spinner color="teal" size="lg" />
          </Flex>
        ) : null
      }
    </Box>
  )
}

export default React.memo(DataTable);
