import { memo } from 'react';
import {
  Box,
  Flex,
  Text,
  List,
} from '@chakra-ui/react';
import HoverCard from 'chakra/HoverCard';

const TableColumnHoverCard = memo(({
  title,
  data = [],
  plain = false,
}) => {
  if (!data?.length) {
    return null;
  }

  if (data?.length === 1) {
    return (
      <Text as="span">
        { data[0] }
      </Text>
    )
  }

  return (
    <HoverCard
      placement="top"
      trigger={(
        <Text
          as="span"
          cursor="default"
          onClick={e => e.stopPropagation()}
        >
          { title }
        </Text>
      )}
    >
      <List.Root
        maxH="340px"
        minW="80px"
        overflow="auto"
        onClick={e => e.stopPropagation()}
      >
        {
          data?.map(item => (
            <List.Item
              key={item}
              mb={1}
              cursor="default"
              listStyleType="none"
            >
              <Flex>
                {
                  !plain ? (
                    <Box
                      mr={2}
                      mt={2}
                      w="5px"
                      h="5px"
                      bg="gray.400"
                      borderRadius={20}
                    />
                  ) : null
                }
                <Box>
                  { item }
                </Box>
              </Flex>
            </List.Item>
          ))
        }
      </List.Root>
    </HoverCard>
  )
});

export default TableColumnHoverCard;
