import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_FILING = 'GET_FILING';
export const GET_FILING_SUCCESS = 'GET_FILING_SUCCESS';
export const GET_FILING_ERROR = 'GET_FILING_ERROR';
export const INVALIDATE_FILING = 'INVALIDATE_FILING';

export const LINK_FILING = 'LINK_FILING';
export const LINK_FILING_SUCCESS = 'LINK_FILING_SUCCESS';
export const LINK_FILING_ERROR = 'LINK_FILING_ERROR';

export const UNLINK_FILING = 'UNLINK_FILING';
export const UNLINK_FILING_SUCCESS = 'UNLINK_FILING_SUCCESS';
export const UNLINK_FILING_ERROR = 'UNLINK_FILING_ERROR';

export const UPSERT_FILING_CUSTOM_FIELD = 'UPSERT_FILING_CUSTOM_FIELD';
export const DELETE_FILING_CUSTOM_FIELD = 'DELETE_FILING_CUSTOM_FIELD';

export const UPSERT_OBJECTION_RESPONDER_SUCCESS = 'UPSERT_OBJECTION_RESPONDER_SUCCESS';

export const ADD_FILING_ATTACHMENTS = 'ADD_FILING_ATTACHMENTS';
export const DELETE_FILING_ATTACHMENTS = 'DELETE_FILING_ATTACHMENTS';

export const GET_FILING_FORM_STATUS = 'GET_FILING_FORM_STATUS';
export const GET_FILING_FORM_STATUS_SUCCESS = 'GET_FILING_FORM_STATUS_SUCCESS';
export const GET_FILING_FORM_STATUS_ERROR = 'GET_FILING_FORM_STATUS_ERROR';

export const GET_FILING_RATE_STATUS = 'GET_FILING_RATE_STATUS';
export const GET_FILING_RATE_STATUS_SUCCESS = 'GET_FILING_RATE_STATUS_SUCCESS';
export const GET_FILING_RATE_STATUS_ERROR = 'GET_FILING_RATE_STATUS_ERROR';

export const getFiling = (filingId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_FILING });
      const res = await axios.get(`${URL}/filings/${filingId}`);
      dispatch({
        type: GET_FILING_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_FILING_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const getFilings = (ids) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/batch`, {
        ids
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const setFiling = (filing) => (dispatch) => {
  dispatch({
    type: GET_FILING_SUCCESS,
    payload: filing,
  });
};

export const getFilingByResourceId = (resourceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${URL}/filings/resource/${resourceId}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const bulkLinkFiling = ({ filters, productId, projectId }) => {
  setAuthToken(localStorage.getItem('token'));

  const payload = {
    productId,
    projectId,
  };

  return async () => {
    try {
      const res = await axios.patch(`${URL}/filings/containers`, {
        filters,
        payload,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const linkFiling = ({ filingId, productId, projectId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({
      type: LINK_FILING,
      payload: { filingId, productId, projectId },
    });
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/containers`, {
        productId,
        projectId,
      });
      dispatch({
        type: LINK_FILING_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: LINK_FILING_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const cloneFiling = ({ filingId, states }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/clone/filings/${filingId}`, { states });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const unLinkFiling = (filingId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({
      type: UNLINK_FILING,
      payload: filingId,
    });
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/containers`, {
        productId: null,
        projectId: null,
      });
      dispatch({
        type: UNLINK_FILING_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: UNLINK_FILING_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const upsertFilingCustomField = (filingId, customField) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/filings/${filingId}/custom-fields`, customField);
      dispatch({
        type: UPSERT_FILING_CUSTOM_FIELD,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFilingCustomField = (filingId, customFieldValueId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/filings/${filingId}/custom-fields/${customFieldValueId}`);
      dispatch({
        type: DELETE_FILING_CUSTOM_FIELD,
        payload: {
          filingId,
          customFieldValueId,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const upsertObjectionResponder = (filingId, objectionId, responders) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/filings-meta/${filingId}/objections/${objectionId}/responders`, { responders });
      dispatch({
        type: UPSERT_OBJECTION_RESPONDER_SUCCESS,
        payload: {
          objectionId,
          responders: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const invalidateFiling = () => (dispatch) => {
  dispatch({ type: INVALIDATE_FILING });
};

export const addFilingAttachment = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/attachments`, data);
      dispatch({
        type: ADD_FILING_ATTACHMENTS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFilingAttachment = ({ filingId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_FILING_ATTACHMENTS,
        payload: attachmentId,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
