import { Box } from '@chakra-ui/react';
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from 'chakra/snippets/hover-card';

const HoverCard = ({
  trigger,
  children,
  placement = 'bottom',
}) => (
  <HoverCardRoot
    size="sm"
    lazyMount
    unmountOnExit
    openDelay={100}
    closeDelay={100}
    positioning={{ placement }}
  >
    <HoverCardTrigger asChild>
      { trigger }
    </HoverCardTrigger>
    <HoverCardContent maxWidth="340px" p={0}>
      <HoverCardArrow />
      <Box p={4}>
        { children }
      </Box>
    </HoverCardContent>
  </HoverCardRoot>
);

export default HoverCard;
