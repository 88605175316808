import { useRef } from 'react';
import {
  DrawerActionTrigger,
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
} from 'chakra/snippets/drawer';
import { Button } from 'chakra/snippets/button';

const Drawer = ({
  open,
  onClose,
  onSubmit,
  children,
  title,
  width,
  submitText = 'Save',
  cancelText = 'Cancel',
  submitButtonProps,
  submitLoadingText = 'Saving',
  submitButtonIcon,
  cancelButtonProps,
}) => {
  const ref = useRef(null);

  return (
    <DrawerRoot
      size="lg"
      lazyMount
      unmountOnExit
      open={open}
      autoFocus={false}
      initialFocusEl={() => ref.current}
      onOpenChange={e => {
        if (!e.open) {
          onClose();
        }
      }}
    >
      <DrawerBackdrop />
      <DrawerContent
        w={width || '100%'}
        {
          ...width && {
            maxW: width
          }
        }
      >
        <DrawerHeader>
          <DrawerTitle>{ title }</DrawerTitle>
        </DrawerHeader>
        <DrawerBody>
          { children }
        </DrawerBody>
        <DrawerFooter>
          <DrawerActionTrigger asChild>
            <Button
              variant="outline"
              pl={10}
              pr={10}
              pt={6}
              pb={6}
              {...cancelButtonProps}
              onClick={() => onClose()}
            >
              { cancelText }
            </Button>
          </DrawerActionTrigger>
          <Button
            loadingText={submitLoadingText}
            pl={10}
            pr={10}
            pt={6}
            pb={6}
            colorPalette="blue"
            { ...submitButtonProps }
            onClick={onSubmit}
          >
            { submitButtonIcon }
            { submitText }
          </Button>
        </DrawerFooter>
        <DrawerCloseTrigger />
      </DrawerContent>
    </DrawerRoot>
  )
}

export default Drawer;
